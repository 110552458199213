import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";


const Hero = ({ title, subTitle, backgroundImage, bgFocus, isBottomMargin = true }) => {
	const pluginImage = getImage(backgroundImage);
	const focus = bgFocus || "center";
	return (
		<BgImage
			Tag="section"
			className={`hero-services position-relative ${isBottomMargin ? "mb-3 mb-md-6 mb-lg-7" : ""}`}
			image={pluginImage}
			style={{ backgroundPosition: focus }}
		>
			<div
				style={{ opacity: 0.4, zIndex: 1 }}
				className="position-absolute bg-primary top-0 start-0 w-100 h-100"
			></div>
			<Container
				style={{ zIndex: 2 }}
				className="py-lg-9 py-5 position-relative"
			>
				<Row className="pt-3 px-3 align-items-lg-end">
					<Col className="pl-0 ps-md-3">
						<p className="mb-lg-0 text-white">{subTitle}</p>
						<h1
							className="pt-3 mb-3 display-4 ubuntu-medium  text-white"
							id="services-hero-title"
						>
							{title}
						</h1>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export default Hero;
